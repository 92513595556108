<template>
  <div class="finished">
    <!-- 合同预览 -->
    <el-dialog title="合同预览" :visible.sync="isaccount" width="40%" center>
      <div class="">
        <div
          v-for="(item, index) in contimgs"
          :key="index"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          "
        >
          <el-button
            @click="examinecontract(item.storageName)"
            v-if="
              item.storageName.slice(-4) == '.pdf' ||
              item.storageName.slice(-4) == '.PDF'
            "
            >点击下载PDF文件<br /><br /><br />{{ item.originalName }}
          </el-button>
          <el-image
            v-else
            style="width: 100px"
            :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
            :preview-src-list="[
              baseUrl + 'third/third/ossGetFile/' + item.storageName,
            ]"
          ></el-image>
        </div>
      </div>
    </el-dialog>
    <div class="title">
      <particulars></particulars>
    </div>
    <div class="contract" v-if="!isListingSales">
      <div class="contract_title">合同信息</div>
      <div class="preview" @click="isaccountClicks(contrac)">下载查看PDF</div>
      <!-- <div @click="download(contrac.contractUrl)">下载</div> -->
    </div>
    <div class="contract1" v-if="isListingSales">
      <div class="contract_title">合同信息</div>
      <div class="preview">
        <el-table :data="contimgs" border style="width: 100%">
          <el-table-column prop="createTime" align="center" label="上传时间">
          </el-table-column>
          <el-table-column prop="originalName" align="center" label="合同名称">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                @click="examinecontract(row.storageName)"
                >{{ row.originalName }}</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="收款状态"
            width="200"
            prop="payStatus"
          >
            <template slot-scope="{ row }">
              <div>
                {{ row.type == 1 ? "合同" : "附件" }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="invoice">
      <div class="invoice_title">支付信息</div>
      <div style="margin: 30px auto 0; width: 85%">
        <el-table :data="tableDatas" border style="width: 100%">
          <el-table-column prop="createTime" align="center" label="支付时间">
          </el-table-column>
          <el-table-column prop="payPrice" align="center" label="单价(元)">
          </el-table-column>
          <el-table-column prop="payAmount" align="center" label="支付金额">
          </el-table-column>
          <el-table-column prop="payCount" align="center" label="支付数量(吨)">
          </el-table-column>
          <el-table-column prop="payType" align="center" label="支付方式">
          </el-table-column>
          <el-table-column
            prop="payBrand"
            align="center"
            label="支付类型"
            v-if="isListingSales&&!isBidding"
          >
          </el-table-column>
          <el-table-column align="center" label="收款状态" prop="payStatus">
            <template slot-scope="scope">
              <div>
                <!-- <el-button
                  @click="accountClick(scope.row.voucherUrl)"
                  v-if="scope.row.voucherUrl"
                  type="text"
                  size="small"
                  >查看付款凭证</el-button
                > -->
                <el-button
                  v-if="scope.row.payStatus == '支付成功'"
                  type="text"
                  size="small"
                  >已收款</el-button
                >
                <el-button
                  v-if="scope.row.payStatus == '待支付'"
                  type="text"
                  size="small"
                  >待收款</el-button
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="付款凭证"
            width="200"
            prop="voucherUrl"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  @click="accountClick(scope.row.voucherUrl)"
                  :disabled="!scope.row.voucherUrl"
                  type="text"
                  size="small"
                  >查看付款凭证</el-button
                >
              </div>
            </template>
          </el-table-column>

          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="220"
            prop="payStatus"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  @click="addconfirm(scope.row)"
                  v-if="options.orderType == '销售'"
                  :disabled="
                    !(
                      scope.row.payStatus == '待支付' &&
                      options.orderType == '销售' &&
                      options.isPublisher == 'true'
                    )
                  "
                  type="text"
                  size="small"
                  >确认金额已到账</el-button
                >
                <el-button
                  @click="addconfirm(scope.row)"
                  v-if="options.orderType == '采购'"
                  :disabled="
                    !(
                      scope.row.payStatus == '待支付' &&
                      options.orderType == '采购' &&
                      !options.isPublisher
                    )
                  "
                  type="text"
                  size="small"
                  >确认金额已到账</el-button
                >

                <el-popover placement="right" width="500" trigger="click">
                  <el-table :data="scope.row?.paymentDetailList || []">
                    <el-table-column
                      width="150"
                      property="goodsName"
                      label="商品名称"
                    ></el-table-column>
                    <el-table-column
                      property="goodsPrice"
                      label="单价"
                    ></el-table-column>
                    <el-table-column
                      property="number"
                      label="数量"
                    ></el-table-column>
                    <el-table-column property="number" label="金额">
                      <template slot-scope="scope">
                        <span>{{
                          (scope.row.goodsPrice * 1000000 * scope.row.number) /
                          1000000
                        }}</span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button
                    slot="reference"
                    type="text"
                    style="background: transparent; margin-left: 10px"
                    size="small"
                    v-if="scope.row?.paymentDetailList && isListingSales"
                    >查看明细</el-button
                  >
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="invoice">
      <div class="invoice_title">运输信息</div>
      <div style="margin: 30px auto 0; width: 85%">
        <el-table :data="tralist" border style="width: 100%">
          <el-table-column prop="createTime" align="center" label="运输时间">
          </el-table-column>
          <el-table-column
            prop="goodsTypeName"
            align="center"
            label="商品种类"
            v-if="isListingSales"
          >
          </el-table-column>
          <el-table-column
            prop="goodsNames"
            align="center"
            label="商品名称"
            v-if="isListingSales"
          >
          </el-table-column>
          <el-table-column prop="trafficCount" align="center" label="运输数量">
          </el-table-column>
          <el-table-column prop="trafficWay" align="center" label="运输方式">
            <template slot-scope="scope">
              {{ scope.row.trafficWay | trafficWay }}
            </template>
          </el-table-column>
          <el-table-column prop="startPlace" align="center" label="起始地">
          </el-table-column>
          <el-table-column prop="destPlace" align="center" label="目的地">
          </el-table-column>
          <el-table-column prop="contacts" align="center" label="联系人">
          </el-table-column>
          <el-table-column prop="contactInfo" align="center" label="联系方式">
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="运输凭证"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="accountClickss(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="invoice">
      <div class="invoice_title">
        货物结算
        <el-button class="title-btn" size="small" @click="exportSignData"
          >导出结算数据</el-button
        >
      </div>

      <div style="margin: 30px auto 0; width: 85%">
        <el-table :data="signlist" border style="width: 100%">
          <el-table-column prop="createTime" align="center" label="结算时间">
          </el-table-column>
          <el-table-column prop="signCount" align="center" label="结算数量">
          </el-table-column>
          <el-table-column prop="deliverStatus" align="center" label="结算状态">
          </el-table-column>
          <el-table-column
            prop="settleAmount"
            align="center"
            label="结算金额"
            v-if="isListingSales"
          >
          </el-table-column>
          <el-table-column align="center" label="结算票据">
            <template slot-scope="scope">
              <el-button
                :disabled="!scope.row.settleFileList.length"
                @click="accountClick(scope.row.settleFileList, 'settlement')"
                type="text"
                size="small"
                >查看
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="煤质检验单"
            width="100"
            prop="payStatus"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  :disabled="!scope.row.qualityFileList.length"
                  @click="accountClick(scope.row.qualityFileList, 'settlement')"
                  type="text"
                  size="small"
                  >查看
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="结算明细"
            width="100"
            prop="payStatus"
            v-if="isListingSales&&!isBidding"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  @click="signDetail(scope.row.signId)"
                  type="text"
                  size="small"
                  >查看
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="200"
          >
            <template slot-scope="scope">
              <div>
                <el-button
                  @click="addconfirms(scope.row)"
                  v-if="options.orderType == '销售'"
                  :disabled="
                    !(
                      (scope.row.deliverStatus == '待结算' &&
                        options.orderType == '销售' &&
                        options.isPublisher == 'true') ||
                      (isListingSales && scope.row.deliverStatus == '待结算')
                    )
                  "
                  type="text"
                  size="small"
                  >确认结算</el-button
                >
                <el-button
                  @click="addconfirms(scope.row)"
                  v-if="options.orderType == '采购'"
                  :disabled="
                    !(
                      scope.row.deliverStatus == '待结算' &&
                      options.orderType == '采购' &&
                      options.isPublisher == 'false'
                    )
                  "
                  type="text"
                  size="small"
                  >确认结算</el-button
                >
                <el-button
                  v-if="scope.row.deliverStatus == '待结算'"
                  :disabled="scope.row.deliverStatus != 0"
                  @click="receivingCancel(scope.row)"
                  type="text"
                  size="small"
                  >取消结算</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="invoice">
      <div class="invoice_title">开票信息</div>
      <div style="margin: 30px auto 0; width: 85%">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="createTime" align="center" label="开票时间">
          </el-table-column>
          <el-table-column
            prop="goodsNames"
            align="center"
            label="开票商品信息"
            v-if="isListingSales"
          >
          </el-table-column>
          <el-table-column prop="invoiceCount" align="center" label="开票数量">
          </el-table-column>
          <el-table-column prop="invoiceAmount" align="center" label="票据金额">
          </el-table-column>
          <!-- <el-table-column
            fixed="right"
            align="center"
            label="结算票据"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="accountClick(scope.row.invoiceUrl)"
                type="text"
                size="small"
                >查看</el-button
              >
            </template>
          </el-table-column> -->
          <el-table-column fixed="right" align="center" label="开票信息">
            <template slot-scope="scope">
              <el-button
                @click="accountClickList(scope.row)"
                type="text"
                size="small"
                >查看</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <span
                v-if="scope.row.confirmStatus == 0 && isPublisher == 'true'"
              >
                <el-button type="text" size="small">未确认</el-button>
              </span>
              <span
                v-if="scope.row.confirmStatus == 0 && isPublisher == 'false'"
              >
                <el-button
                  @click="noteconfirm(scope.row)"
                  type="text"
                  size="small"
                  >票据确认</el-button
                >
              </span>
              <span v-if="scope.row.confirmStatus == 1">
                <el-button type="text" size="small">已确认</el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 预览 -->
    <el-dialog title="预览" :visible.sync="preview" width="30%" center>
      <div class="previewIm">
        <!-- <img :src="previewImage" alt="" /> -->
        <el-image
          v-if="previewImage"
          style="width: 200px"
          :src="previewImage"
          :preview-src-list="[previewImage]"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="preview = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览 -->
    <el-dialog title="运输凭证预览" :visible.sync="previews" width="30%" center>
      <div class="block">
        <el-carousel trigger="click" :autoplay="false" height="300px">
          <el-carousel-item
            v-for="(item, index) in previewImages"
            :key="index"
            class="image_item"
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-button
              @click="examinecontract(item.storageName)"
              v-if="
                item.storageName.slice(-4) == '.pdf' ||
                item.storageName.slice(-4) == '.PDF'
              "
            >
              点击查看PDF文件<br /><br /><br />{{ item.storageName }}</el-button
            >
            <el-image
              v-else
              :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + item.storageName,
              ]"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="previews = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 签收查看票据
     -->
    <el-dialog title="预览" :visible.sync="dialogopreviews" width="40%" center>
      <div class="dialogopreviews_item">
        <div class="item_content">
          <div>结算数量：</div>
          <div>{{ thepreview.signCount }}吨</div>
        </div>
        <div class="item_content">
          <div>煤炭检验单</div>
          <!-- invoiceUrl -->
          <div v-if="thepreview.invoiceUrl == ''">未上传</div>
          <div v-else>
            <div :span="16">
              <el-button
                class="el-button el-button--small"
                @click="examinecontract(thepreview.inspectUrl)"
                v-if="
                  thepreview.inspectUrl &&
                  (thepreview.inspectUrl.slice(-4) == '.pdf' ||
                    thepreview.inspectUrl.slice(-4) == '.PDF')
                "
              >
                点击查看PDF文件<br /><br />{{
                  thepreview.inspectUrl
                }}</el-button
              >
              <el-image
                v-if="thepreview.inspectUrl"
                style="width: 100px"
                :src="
                  baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl
                "
                :preview-src-list="[
                  baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl,
                ]"
              ></el-image>
            </div>
            <!-- <el-image
              v-if="thepreview.invoiceUrl"
              style="width: 200px"
              :src="baseUrl + 'third/third/ossGetFile/' + thepreview.invoiceUrl"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + thepreview.invoiceUrl,
              ]"
            >
            </el-image> -->
          </div>
        </div>
        <div class="item_content">
          <div>结算单据</div>
          <!-- inspectUrl -->
          <div v-if="thepreview.invoiceUrl == ''">未上传</div>
          <div v-else>
            <!-- <el-image
              v-if="thepreview.inspectUrl"
              style="width: 200px"
              :src="baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + thepreview.inspectUrl,
              ]"
            >
            </el-image> -->
            <div :span="16">
              <el-button
                class="el-button el-button--small"
                @click="examinecontract(thepreview.invoiceUrl)"
                v-if="
                  thepreview.invoiceUrl &&
                  (thepreview.invoiceUrl.slice(-4) == '.pdf' ||
                    thepreview.invoiceUrl.slice(-4) == '.PDF')
                "
              >
                点击查看PDF文件<br /><br />{{
                  thepreview.invoiceUrl
                }}</el-button
              >
              <el-image
                v-if="thepreview.invoiceUrl"
                style="width: 100px"
                :src="
                  baseUrl + 'third/third/ossGetFile/' + thepreview.invoiceUrl
                "
                :preview-src-list="[
                  baseUrl + 'third/third/ossGetFile/' + thepreview.invoiceUrl,
                ]"
              ></el-image>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="开票信息预览" width="30%" :visible.sync="invoiceUrlVis">
      <div
        class="block"
        v-for="(item, index) in invoiceUrlList"
        :key="index"
        style="display: flex; justify-content: center"
      >
        <el-button
          @click="examinecontract(item)"
          v-if="
            (item && item.slice(-4) == '.pdf') ||
            (item && item.slice(-4) == '.PDF')
          "
        >
          点击查看PDF文件<br /><br /><br />{{ item }}</el-button
        >
        <el-image
          v-else
          :src="baseUrl + 'third/third/ossGetFile/' + item"
          :preview-src-list="[baseUrl + 'third/third/ossGetFile/' + item]"
        ></el-image>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-button type="primary" @click="invoiceUrlVisFlse">确定</el-button>
      </div>
    </el-dialog>

    <div>
      <el-dialog title="预览" width="30%" :visible.sync="receivingVisi">
        <div class="block">
          <el-carousel trigger="click" :autoplay="false" min-height="300px">
            <el-carousel-item
              v-for="(item, index) in ransportviews"
              :key="index"
              class="image_item"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-button
                @click="examinecontract(item.storageName)"
                v-if="
                  (item.storageName && item.originalName.slice(-4) == '.pdf') ||
                  (item.storageName && item.originalName.slice(-4) == '.PDF')
                "
              >
                点击查看PDF文件<br /><br /><br />{{ item }}</el-button
              >
              <el-image
                v-else
                :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                :preview-src-list="[
                  baseUrl + 'third/third/ossGetFile/' + item.storageName,
                ]"
              ></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <!-- <el-button type="primary" @click="receivingVisi=false">确定</el-button> -->
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <el-button type="primary" @click="receivingVisi = false"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 结算详情 -->
    <div>
      <el-dialog title="结算明细" width="1000px" :visible.sync="signDiolog">
        <div>
          <el-row
            v-for="(item, index) in signDetailData.settleDetailList"
            :key="index"
          >
            <el-col :span="4">结算商品名称:</el-col>
            <el-col :span="4">{{ item.goodsName }}</el-col>
            <el-col :span="4">结算数量:</el-col>
            <el-col :span="4">{{ item.number }}</el-col>
            <el-col :span="4">结算单价:</el-col>
            <el-col :span="4">{{ item.goodsPrice }}</el-col>
          </el-row>
          <el-row style="margin-top: 30px">
            <el-col :span="4">结算单据:</el-col>
            <el-col :span="8">
              <el-button
                class="el-button el-button--small"
                @click="fileView(signDetailData?.settleFileList, 1)"
                v-if="signDetailData?.settleFileList?.length"
              >
                点击查看</el-button
              >
            </el-col>
            <el-col :span="4">煤质检验单:</el-col>
            <el-col :span="8">
              <el-button
                class="el-button el-button--small"
                @click="fileView(signDetailData?.qualityFileList, 2)"
                v-if="signDetailData?.qualityFileList?.length"
              >
                点击查看</el-button
              >
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px">
            <el-col :span="4">结算总数量:</el-col>
            <el-col :span="8">{{ signDetailData.signCount }}</el-col>
            <el-col :span="4">结算总金额:</el-col>
            <el-col :span="8">{{ signDetailData.settleAmount }}</el-col>
          </el-row>
        </div>
        <el-dialog
          title="预览"
          width="30%"
          append-to-body
          :visible.sync="detailReceivingFileView"
        >
          <div class="block">
            <el-carousel trigger="click" :autoplay="false" height="300px">
              <el-carousel-item
                v-for="(item, index) in receivingFilesDetail"
                :key="index"
                class="image_item"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-button
                  @click="examinecontract(item.storageName)"
                  v-if="
                    item.storageName.slice(-4) == '.pdf' ||
                    item.storageName.slice(-4) == '.PDF'
                  "
                >
                  点击查看PDF文件<br /><br /><br />{{
                    item.storageName
                  }}</el-button
                >
                <el-image
                  v-else
                  :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
                  :preview-src-list="[
                    baseUrl + 'third/third/ossGetFile/' + item.storageName,
                  ]"
                ></el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-dialog>
      </el-dialog>
    </div>
    <el-dialog
      title="预览"
      width="30%"
      append-to-body
      :visible.sync="receivingFileView1"
    >
      <div class="block">
        <el-carousel trigger="click" :autoplay="false" height="300px">
          <el-carousel-item
            v-for="(item, index) in receivingFiles"
            :key="index"
            class="image_item"
            style="display: flex; align-items: center; justify-content: center"
          >
            <el-button
              @click="examinecontract(item.storageName)"
              v-if="
                item.storageName.slice(-4) == '.pdf' ||
                item.storageName.slice(-4) == '.PDF'
              "
            >
              点击查看PDF文件<br /><br /><br />{{ item.storageName }}</el-button
            >
            <el-image
              v-else
              :src="baseUrl + 'third/third/ossGetFile/' + item.storageName"
              :preview-src-list="[
                baseUrl + 'third/third/ossGetFile/' + item.storageName,
              ]"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import particulars from "@/layout/particulars.vue";
const { baseUrl } = require("@/config");
import {
  invoicepage,
  paymentpage,
  trafficpage,
  signpage,
  detailByOrder,
  addconfirm,
  confirmInvoice,
  confirmSettle,
  getSignDetail,
  exportSign,
  cancelSettle,
} from "@/api/public";
export default {
  components: {
    particulars,
  },
  data() {
    return {
      ransportviews: [],
      receivingVisi: false,
      contimgs: [],
      isaccount: false,
      preview: false,
      previewImage: "",
      previewImages: [],
      //合同
      contrac: {},
      baseUrl: baseUrl,
      //开票列表
      tableData: [],
      //签收信息
      signlist: [],
      //运输信息
      tralist: [],
      //支付记录
      tableDatas: [],
      dialogopreviews: false,
      thepreview: {},
      isPublisher: false,
      options: {},
      previews: false,
      invoiceUrlList: [],
      invoiceUrlVis: false,
      signDiolog: false,
      signDetailData: {},
      receivingFileView: false,
      receivingFiles: [],
      receivingFileView1: false,
      detailReceivingFileView: false,
      receivingFilesDetail: [],
    };
  },
  mounted() {
    this.isPublisher = this.$route.query.isPublisher;
    this.options = this.$route.query;
    console.log(this.options);
    this.getlist();
  },
  filters: {
    trafficWay(val) {
      let _data = {
        1: "公路运输",
        2: "铁路运输",
        3: "公铁联运",
        4: "海运",
      };
      return _data[val];
    },
  },
  computed: {
    // 是否挂牌销售
    isListingSales() {
      return (
        (this.$route.query.tradeType == "挂牌" &&
          this.$route.query.orderType == "销售")
      );
    },
    isBidding() {
      return this.$route.query.tradeType == "竞价";
    },
  },
  methods: {
    receivingCancel(row) {
      cancelSettle({ signId: row.signId }).then((res) => {
        if (res.code == 0) {
          this.$message.success("取消成功");
          this.signgetlist();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    exportSignData() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 1000000,
      };
      exportSign(config).then((res) => {
        const blob = new Blob([res], {
          // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
          type: "application/vnd.ms-excel", // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
        });
        let link = document.createElement("a");
        let body = document.querySelector("body");
        link.href = window.URL.createObjectURL(blob); //
        link.style.display = "none"; // 让这个a标签不可见
        link.download = "结算数据表"; //文件名称
        body.appendChild(link);
        link.click(); // 创建了新的a标签之后模拟点击事件，开始传输文件
        body.removeChild(link); // 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
        window.URL.revokeObjectURL(link.href); // 移除之前使用createObjectURL创建的URL，垃圾回收
      });
    },
    fileView(files, type) {
      this.receivingFilesDetail = files;
      this.detailReceivingFileView = true;
    },
    signDetail(id) {
      getSignDetail({ signId: id }).then((res) => {
        if (res.code == 0) {
          this.signDiolog = true;
          this.signDetailData = res.data;
        }
      });
    },
    accountClickList(row) {
      this.invoiceUrlList = row.invoiceUrlList;
      this.invoiceUrlVis = true;
    },
    invoiceUrlVisFlse() {
      this.invoiceUrlList = [];
      this.invoiceUrlVis = false;
    },
    accountClickss(row) {
      console.log(row);
      this.previews = true;
      this.previewImages = row.trafficDocUrls;
      //  this.previewImages=row.
      // this.previewImages = this.baseUrl + "third/third/ossGetFile/" + row;
      // if (row) {
      //     let a = row.split(".");
      //     if (a[1] == "png" || a[1] == "jpg" || a[1] == "jpeg") {
      //       this.previews = true;
      //       this.previewImages = this.baseUrl + "third/third/ossGetFile/" + row;
      //     } else {
      //       window.open(this.baseUrl + "third/third/ossGetFile/" + row);
      //     }
      // } else {
      //   this.$message("暂无文件");
      // }
    },
    examinecontract(item) {
      let url = this.baseUrl + "third/third/ossGetFile/" + item;
      window.open(url);
    },
    noteconfirm(row) {
      console.log(row.invoiceId);
      let config = {
        invoiceId: row.invoiceId,
      };
      confirmInvoice(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.$message({
            message: "确认成功",
            type: "success",
          });
          this.invoicepage();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    yunshuClicks(row) {
      console.log(row.trafficDocUrls);
      this.ransportviews = row.trafficDocUrls;
      this.receivingVisi = true;
    },
    accountClicks(row) {
      console.log(row);
      console.log(this.baseUrl + "third/third/ossGetFile/" + row.invoiceUrl);
      this.thepreview = row;
      this.dialogopreviews = true;
    },
    getlist() {
      this.signgetlist();
      this.tralists();
      this.paymentpage();
      this.invoicepage();
      this.getorder();
    },
    // 货物结算
    signgetlist() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 100,
      };
      signpage(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliverStatus == 0) {
            item.deliverStatus = "待结算";
          } else if (item.deliverStatus == 1) {
            item.deliverStatus = "已结算";
          } else if (item.deliverStatus == 2) {
            item.deliverStatus = "等待对方结算";
          } else if (item.deliverStatus == -1) {
            item.deliverStatus = "取消结算";
          }
        });
        this.signlist = list;
        console.log(this.signlist);
      });
    },
    //运输记录
    tralists() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 100,
      };
      trafficpage(config).then((res) => {
        let list = res.data.records;
        list.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
        });
        this.tralist = list;
        this.tralist.forEach((item) => {
          item.goodsNames = item.trafficDetailList.reduce((pre, cur, index) => {
            return (
              pre +
              (cur.goodsName +
                (index == item.trafficDetailList.length - 1 ? "" : ","))
            );
          }, "");
          item.goodsTypeName = item.trafficDetailList.reduce(
            (pre, cur, index) => {
              return (
                pre +
                (cur.goodsTypeName +
                  (index == item.trafficDetailList.length - 1 ? "" : ","))
              );
            },
            ""
          );
        });
        console.log(this.tralist);
      });
    },
    //支付记录
    paymentpage() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 100,
      };
      paymentpage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            if (item.payBrand != 0) {
              item.payCount = "";
            }
            item.createTime = item.createTime.replace("T", " ");
            if (item.payStatus == 0) {
              item.payStatus = "待支付";
            } else if (item.payStatus == 1) {
              item.payStatus = "支付成功";
            } else if (item.payStatus == 2) {
              item.payStatus = "支付失败";
            }
            if (item.payType == 0) {
              item.payType = "未知";
            } else if (item.payType == 1) {
              item.payType = "线下支付";
            } else if (item.payType == 2) {
              item.payType = "线上支付";
            } else if (item.payType == 3) {
              item.payType = "承诺付款";
            }
            item.payPrice = item.payBrand == 0 ? item.payPrice : "";
            if (item.payBrand == 0) {
              item.payBrand = "按数量支付";
            } else if (item.payBrand == 1) {
              item.payBrand = "预付款支付";
            } else if (item.payBrand == 2) {
              item.payBrand = "履约保证金";
            } else {
              item.payBrand = "";
            }
          });
          this.tableDatas = list;
          console.log(this.tableDatas);
        }
      });
    },
    //确认结算
    addconfirms(item) {
      let config = {
        signId: item.signId,
      };
      confirmSettle(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "已确认结算",
            type: "success",
          });
          this.signgetlist();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    //确认金额已到账
    addconfirm(row) {
      console.log(row);
      this.$confirm("确认金额到账后无法修改，请谨慎操作!", "提示", {
        confirmButtonText: "已到账",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let config = {
            paymentId: row.paymentId,
          };
          addconfirm(config).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "确认成功!",
              });
              this.getlist();
            } else {
              this.$message({
                type: "info",
                message: res.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消确认",
          });
        });
    },
    //开票列表
    invoicepage() {
      let config = {
        orderId: this.$route.query.orderId,
        page: 1,
        size: 100,
      };
      invoicepage(config).then((res) => {
        if (res.code == 0) {
          let list = res.data.records;
          list.forEach((item) => {
            item.createTime = item.createTime.replace("T", " ");
            if (item.trafficWay == 0) {
              item.trafficWay = "未知";
            } else if (item.trafficWay == 1) {
              item.trafficWay = "公路";
            } else if (item.trafficWay == 2) {
              item.trafficWay = "铁路";
            } else if (item.trafficWay == 3) {
              item.trafficWay = "公铁联运";
            } else if (item.trafficWay == 4) {
              item.trafficWay = "海运";
            }
          });
          this.tableData = list;
          this.tableData.forEach((item) => {
            if (item.invoiceDetailList) {
              item.goodsNames = item.invoiceDetailList.reduce(
                (pre, cur, index) => {
                  return (
                    pre +
                    (cur.goodsName +
                      (index == item.invoiceDetailList.length - 1 ? "" : ","))
                  );
                },
                ""
              );
            }
          });
          // console.log(this.tableData,22334455);
        }
      });
    },
    //获取合同
    getorder() {
      let orderId = this.$route.query.orderId;
      detailByOrder(orderId).then((res) => {
        if (res.code == 0) {
          this.contrac = res.data;
          console.log(
            this.contrac,
            "this.contracthis.contracthis.contracthis.contracthis.contrac"
          );
          let contactList = this.contrac.contractUrls.map((item) => {
            return Object.assign(item, {
              type: 1,
              createTime: this.contrac.createTime,
            });
          });
          let annexList = this.contrac.annexInfoList
            ? this.contrac.annexInfoList.reduce((pre, cur) => {
                let item = cur.contractUrls.map((annex) => {
                  return Object.assign(annex, { createTime: cur.createTime });
                });
                return [...pre, ...item];
              }, [])
            : [];
          let annexList1 = annexList.map((item) => {
            return Object.assign(item, { type: 2 });
          });
          this.contimgs = [...contactList, ...annexList1];
        }
      });
    },
    //下载
    download(row) {
      window.location.href = this.baseUrl + "third/third/ossGetFile/" + row;
    },
    // 合同预览
    isaccountClicks(row) {
      this.isaccount = true;
    },
    accountClick(row, type) {
      if (type == "settlement") {
        this.receivingFiles = row;
        this.receivingFileView1 = true;
        return;
      } else {
        if (row) {
          let a = row.split(".");
          if (a[1] == "png" || a[1] == "jpg" || a[1] == "jpeg") {
            this.preview = true;
            this.previewImage = this.baseUrl + "third/third/ossGetFile/" + row;
          } else {
            window.open(this.baseUrl + "third/third/ossGetFile/" + row);
          }
        } else {
          this.$message("暂无文件");
        }
      }
    },
  },
};
</script>
<style lang="scss">
.el-image__inner {
  top: 0;
  left: 0;
  transform: translate(0, 0);
}
</style>
<style lang="scss" scoped>
.dialogopreviews_item {
  margin: 0 auto;
  text-align: center;

  .item_content {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;

    > :nth-child(1) {
      width: 200px;
    }
  }

  img {
    width: 200px;
    height: 200px;
  }
}
.finished {
  width: 100%;
  .title {
    width: 50%;
    margin: 20px auto;
    min-width: 1200px;
  }
  .contract {
    width: 1200px;
    display: flex;
    line-height: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-left: 20px;
    margin: 0 auto;
    .contract_title {
      font-size: 20px;
      font-weight: bold;
    }
    .preview {
      margin-left: 20px;
    }
  }
  .contract1 {
    width: 1200px;
    line-height: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-left: 20px;
    margin: 0 auto;
    .contract_title {
      font-size: 20px;
      font-weight: bold;
    }
    .preview {
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  .invoice {
    margin: 20px auto;
    width: 1200px;
    min-width: 1200px;
    .invoice_title {
      font-size: 20px;
      border-bottom: 1px solid #ccc;
      padding-left: 20px;
      padding-bottom: 10px;
      margin: 0 auto;
      font-weight: bold;
      position: relative;
      .title-btn {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
}
.previewIm {
  text-align: center;
  img {
    width: 100%;
  }
}
</style>
